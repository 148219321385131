<template>
    <div>
        <Loading v-if="isLoading"/>

        <div class="p-grid" v-show="!isLoading">

            <!-- Toolbar -->    
            <TitlePage 
                title="USUARIOS" 
                :reset="false" 
            />

            <!-- Actions -->
            <div class="col-12"> 
                <span class="p-buttonset">
                    <Btn
                        to="/users/create"
                        label='NUEVO'
                        icon='fas fa-plus'
                        classBtn='p-button-sm'
                    />
                </span>
            </div>

            <!-- Table Result -->
            <div v-show="dataSet.length">
                <div class="col-12">
                    <DataTable 
                        :value="dataSet" 
                        :rows="20"
                        :rowClass="rowClass"
                        :paginator="true" 
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords}"
                        responsiveLayout="scroll" 
                        :rowHover="true"
                        v-model:filters="filters" 
                        filterDisplay="row"
                        :globalFilterFields="['id','username','phone_number','email', 'last_name', 'first_name']"
                    >
                        <template #header>
                            <div class="flex justify-content-start">
                                
                                <span class="p-input-icon-left ">
                                    <i class="fas fa-search" />
                                    <InputText v-model="filters['global'].value" class="ml-2" placeholder="Filtrar Datos" />
                                </span>
                            </div>
                        </template>

                        <template #empty>{{ strings.es.dataTable.empty }}</template>
                        <template #loading>{{ strings.es.dataTable.loading }}</template>

                        <Column 
                            field="id" 
                            header="ID" 
                            :sortable="true" 
                            :style="{width:'50px'}"></Column>

                        <Column 
                            :sortable="true" 
                            header="Apellido y Nombre">
                            <template #body="row">{{  row.data.last_name + ', ' + row.data.first_name  }}</template>
                        </Column>

                        <Column 
                            field="username"
                            :sortable="true" 
                            header="Usuario" 
                            :style="{width:'50px'}"></Column>

                        <Column 
                            header="Clave" 
                            :sortable="true" 
                            :style="{width:'140px', 'text-align':'center'}">
                            <template #body="row">
                                <Button 
                                    icon='fas fa-key'
                                    class="p-button-text w-full p-button-secondary"
                                    @click="resetPwd(row.data.id)"
                                />
                            </template>
                        </Column>

                        <Column 
                            header="Roles" 
                            :sortable="true" 
                            :style="{width:'140px', 'text-align':'center'}">
                            <template #body="row">
                                <Button 
                                    icon='fas fa-eye'
                                    :disabled="!row.data.roles.length"
                                    class="p-button-text w-full p-button-secondary"
                                    @click="viewRoles(row.data)"
                                />
                            </template>
                        </Column>

                        <Column 
                            field="phone_number" 
                            :sortable="true" 
                            header="Teléfono"></Column>
                        
                        <Column 
                            field="email"
                            :sortable="true" 
                            header="Email" 
                            :style="{width:'50px'}"></Column>

                        <Column 
                            header="Estado" 
                            :sortable="true" 
                            :style="{width:'50px'}">
                            <template #body="row">{{  (row.data.state) ? 'Activo' : 'Inactivo'  }}</template>
                        </Column>

                        <Column 
                            header="Modificado"
                            :sortable="true" >
                            <template #body="row">{{ (row.data.updated_at != null) ? $moment.utc(row.data.updated_at, 'YYYY-MM-DD HH:mm:ss').format("DD-MM-YYYY HH:mm") : '-' }}</template>
                        </Column>

                        <Column :style="{width:'275px', 'text-align':'center' }">
                            <template #body="slotProps">
                                <span class="p-buttonset">
                                    <Btn
                                        v-bind:to="'/users/'+slotProps.data.id"
                                        label='EDITAR'
                                        iconPos='left'
                                        icon='fas fa-edit'
                                        classBtn='p-button-info p-button-text p-button-sm'
                                    />
                                    <Button 
                                        v-on:click="deleteUser(slotProps.data.id)"
                                        body={this.onIndexTemplate}
                                        icon="fas fa-edit" 
                                        iconPos="left" 
                                        label='ELIMINAR'
                                        class='p-button-danger p-button-text p-button-sm'
                                    />
                                </span>
                            </template>
                        </Column>
                    </DataTable>
                </div>
            </div>

            <NoResult v-show="!dataSet.length && !isLoading"/>
        </div>

        <Dialog :header="dialogRoles.title" v-model:visible="showDialogRoles" :style="{width: '50vw'}" :modal="true">
            <div class="mb-3">
                <DataTable 
                    :value="dialogRoles.dataSet" 
                    :rows="20"
                    :rowClass="rowClass"
                    responsiveLayout="scroll" 
                    :rowHover="true">
                    <Column 
                        field="id" 
                        header="ID" 
                        :sortable="true" 
                        :style="{width:'50px'}"></Column>
                    <Column 
                        header="Nombre" 
                        field="name" 
                        :sortable="true"></Column>
                    <Column 
                        header="Asignado"
                        :sortable="true" >
                        <template #body="row">{{ $moment(row.data.created_at, 'YYYY-MM-DD HH:mm:ss').format("DD-MM-YYYY HH:mm") }}</template>
                    </Column>
                </DataTable>
            </div>
            <template #footer>
                <div class="grid">
                    <div class="col text-left">
                        <Button 
                            label="Cerrar" 
                            icon="fas fa-times" 
                            @click="showDialogRoles = !showDialogRoles" 
                            class="p-button-danger p-button-text"/>
                    </div>
                    <div class="col text-right">
                        <Btn 
                            :blank="true"
                            :to="'/users/'+dialogRoles.id"
                            label="EDITOR COMPLETO"
                            icon='fas fa-tools'
                            classBtn='p-button-success p-button-outlined'
                        />
                    </div>
                </div>
            </template>
        </Dialog>
    </div>
</template>

<script>
    import apiService from '../../services/apiService.js'
    import { FilterMatchMode } from 'primevue/api';

    export default {
        data(){
            return{
                dataSet: [],
                isLoading: false,
                dialogRoles: {
                    id: '',
                    title: '',
                    dataSet: '',
                },
                showDialogRoles: false,
                filters: {
                    'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
                    'id': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
                    'username': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
                    'last_name': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
                    'first_name': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
                    'phone_number': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
                    'email': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
                },
            }
        },
        async mounted(){
            this.index()
        },
        methods: {
            async index(){
                this.isLoading = true
                apiService.User.get()
                .then((result) => {
                    this.dataSet = result
                    this.isLoading = false
                })
            },
            async deleteUser(id){
                this.$confirm.require({
                    message: 'Está seguro de que desea continuar?',
                    header: 'Confirmación',
                    icon: 'pi pi-exclamation-triangle',
                    accept: () => {
                        this.isLoading = true
                        apiService.User.delete(id)
                        .then((result) => {
                            if(result.status == 'success'){
                                this.FormSuccess(result.message)
                                this.dataSet.splice(this.dataSet.findIndex(x => x.id === id), 1)
                                this.isLoading = false
                            }else{
                                this.FormErrors(result)
                                this.isLoading = false
                            }
                        }).catch((e) => {
                            this.FormRequestFail(e);
                            this.isLoading = false
                        });
                    },
                    reject: () => {
                        //callback to execute when user rejects the action
                    }
                });
            },
            viewRoles(data){
                this.showDialogRoles = true;
                this.dialogRoles.id = data.id;
                this.dialogRoles.title = "ROLES DE " + data.first_name.toUpperCase() +', '+data.last_name.toUpperCase();
                this.dialogRoles.dataSet = data.roles;
            },
            async resetPwd(id){
                this.$confirm.require({
                    message: 'Está seguro de que desea continuar?. Se enviará un link de recuperación al email registrado.',
                    header: 'Confirmación',
                    icon: 'fas fa-key',
                    accept: () => {
                        this.isLoading = true
                        apiService.User.resetPwd(id)
                        .then((result) => {
                            if(result.status == 'success'){
                                this.FormSuccess(result.message)
                                this.isLoading = false
                            }else{
                                this.FormErrors(result)
                                this.isLoading = false
                            }
                        }).catch((e) => {
                            this.FormRequestFail(e);
                            this.isLoading = false
                        });
                    },
                    reject: () => {
                        //callback to execute when user rejects the action
                    }
                });
            },
            // Styles
            rowClass(data) {
                return !data.state ? 'bg-pink-50': null;
            }
        }
    }
</script>